import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';

import { Configuration } from 'app/app.constants';
import { GetAccountStatementCommand, GetAccountStatementDownloadCommand, GetAgentAccountStatementCommand, TransactionInfoList } from 'app/models/accountStatement';


@Injectable({
  providedIn: 'root'
})
export class AccountStatementService {

  constructor(private http: HttpClient) { }

  public generateAccountStatement(businessId: number, accountGuid: string, fromDate: Date, toDate: Date, isSavingsAccount: boolean, page: number, pageSize: number): Observable<TransactionInfoList> {
    var command = new GetAccountStatementCommand();
    command.BusinessId = businessId,
      command.AccountGuid = accountGuid,
      command.FromDate = fromDate,
      command.ToDate = toDate,
      command.Page = page,
      command.PageSize = pageSize,
      command.Sort = null;
    command.IsSavingsAccount = isSavingsAccount;
    return this.http.post(Configuration.GenerateAccountStatement, command)
      .map(data => { return new TransactionInfoList(data); })
      .catch(err => { return throwError(err) });
  }
  
  public generateAgentAccountStatement(accountId: number, fromDate: Date, toDate: Date, page: number, pageSize: number): Observable<TransactionInfoList> {
    var command = new GetAgentAccountStatementCommand();
    command.AccountId = accountId,
      command.FromDate = fromDate,
      command.ToDate = toDate,
      command.Page = page,
      command.PageSize = pageSize,
      command.Sort = null;
    return this.http.post(Configuration.GenerateAgentAccountStatement, command)
      .map(data => { return new TransactionInfoList(data); })
      .catch(err => { return throwError(err) });
  }

  downloadAccountStatement(businessId: number, accountGuid: string, fromDate: Date, toDate: Date, isSavingsAccount: boolean, isPdfDownload: boolean): Observable<any> {
    let command: GetAccountStatementDownloadCommand =
    {
      BusinessId: businessId,
      AccountGuid: accountGuid,
      FromDate: fromDate,
      ToDate: toDate,
      IsSavingsAccount: isSavingsAccount
    };
    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };
    let downloadUrl: string = isPdfDownload ? Configuration.DownloadAccountStatementPdf : Configuration.DownloadAccountStatementCsv;
    return this.http.post(downloadUrl, command, httpOptions)
      .map((response: any) => {
        if (response.status == 200) {
          let fileName = response.headers.get('x-file-name');
          response.body.fileName = fileName;
          return response.body;
        }
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error);
      });
  }

  downloadAgentAccountStatement(accountId: number, fromDate: Date, toDate: Date, isPdfDownlaod: boolean): Observable<any> {
    var command = new GetAgentAccountStatementCommand();
    command.AccountId = accountId,
      command.FromDate = fromDate,
      command.ToDate = toDate;
    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };
    let downloadUrl: string = isPdfDownlaod? Configuration.DownloadAgentAccountStatementPdf : Configuration.DownloadAgentAccountStatementCsv;
    return this.http.post(downloadUrl, command, httpOptions)
      .map((response: any) => {
        if (response.status == 200) {
          let fileName = response.headers.get('x-file-name');
          response.body.fileName = fileName;
          return response.body;
        }
      })
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(error);
      });
  }
}