import { CurrencyService } from "../services/currency.service"

export class Business {
    Id: number;
    Name: string;
    Currency: Currency;
    PhoneNumber: string;
    ExternalCustomerGuid: string;
    public constructor(data: any = {}) {
        this.Id = data.id || 0;
        this.Name = data.name || '';
        this.PhoneNumber = data.phoneNumber || '';
        this.ExternalCustomerGuid = data.externalCustomerGuid || '';
        this.Currency = data.currency ? new Currency(data.currency) : null;
    }
}

export class Account {
    AccountId: string;
    AccountType: string;
    Balance: number;
    Currency: Currency;

    public constructor(data: any = {}) {

        this.AccountId = data.accountId;
        this.AccountType = data.accountType;
        this.Balance = data.balance || 0;
        this.Currency = data.currency ? new Currency(data.currency) : null;
    }
}

export class AccountList {
    public List: Array<Account>;

    public constructor(data: any = {}) {
        this.List = new Array<Account>();
        if (data) {
            data.forEach(item => {
                this.List.push(new Account(item));
            });
        }
    }
}

export class AgentGroupListDetailsView {
    public Data: Array<AgentGroupDetailsView>;
    public Total: number;

    public constructor(data: any = {}) {
        this.Total = data.total;
        this.Data = new Array<AgentGroupDetailsView>();
        if (data.data) {
            data.data.forEach(item => {
                this.Data.push(new AgentGroupDetailsView(item));
            });
        }
    }
}

export class AgentGroupDetailsView {
    AgentName: string;
    AgentNumber: string;
    CustomerGuid: string;
    AccountId: any;
    PhoneNumber: string;
    HasAccount: boolean;
    Balance: number;

    public constructor(data: any = {}) {
        this.AgentName = data.agentName;
        this.AgentNumber = data.agentNumber;
        this.CustomerGuid = data.customerGuid;
        this.AccountId =   data.accountId;
        this.PhoneNumber = data.phoneNumber;
        this.HasAccount = data.hasAccount;
        this.Balance = data.balance;
    }
}


export class Currency {
    CurrencyId: number;
    IsoCode: string;
    Name: string;
    DisplayCode: string;
    MaxDecimalNumber: number;
    IsAirtimeTopupAvailable: boolean;

    public constructor(data: any = {}) {
        this.CurrencyId = data.currencyId || 0;
        this.IsoCode = data.isoCode || '';
        this.Name = data.name || '';
        this.DisplayCode = data.displayCode || '';
        this.MaxDecimalNumber = data.maxDecimalNumber;
        this.IsAirtimeTopupAvailable = data.isAirtimeTopupAvailable || false;
    }

}

export class Country {
    public isoCode: string;
    public name: string;
    public countryId: number;
}
