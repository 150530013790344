import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TransactionInfoList } from 'app/models/accountStatement';
import { PaginationConfig } from 'app/models/paginationConfig';
import { AccountStatementService } from 'app/services/account-statement.service';
import { BusinessService } from 'app/services/business.service';
import { NotificationService } from 'app/services/notification.service';
import { MixpanelUtilService } from 'app/services/mixpanel-util.service';
import { Configuration } from 'app/app.constants';
import { Account } from 'app/models/business';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-agent-account-statement',
  templateUrl: './agent-account-statement.component.html',
  styleUrls: ['./agent-account-statement.component.scss']
})

export class AgentAccountStatementComponent implements OnInit {

  public pageTitle = "Account Statement";
  public transactionList: TransactionInfoList = new TransactionInfoList;
  public busy: Subscription;
  public paginationConfig = new PaginationConfig('Transactions', 'account-statement', 10);
  public form: FormGroup;
  public startDate: any;
  public endDate: any;
  public maxDate: Date = new Date();
  public accountId: number;
  public isDownloadButtonEnable: boolean = false;
  selectedDownloadValue: string;
  maxNumberOfItems: number;
  savingsAccount: Account;
  constructor(public dialogRef: MatDialogRef<AgentAccountStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private accountStatementService: AccountStatementService,
    private businessService: BusinessService,
    private notif: NotificationService,
    private fb: FormBuilder,
    private mixPanelUtilService: MixpanelUtilService
  ) {
    if(data){
      console.log(data?.accountId);
      this.accountId = data?.accountId;
    }
   }

  ngOnInit(): void {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required]

    });

    this.maxNumberOfItems = parseInt(Configuration.NumofOfItemsInDownload);
    this.loadSavingsAccountBalance();
  }

  loadSavingsAccountBalance() {
    this.busy = this.businessService.GetSavingsBalance().subscribe(
      data => {
        this.savingsAccount = data;
      }, error => {
        console.log(error.status);

      }
    );
  }

  GetAccountStatement() {
    if (this.validateDates()) {
      this.notif.error('End date should be greater than or equal to Start date.');
      return;
    }
    this.startDate = this.form.controls.fromDate.value.format();
    this.endDate = this.form.controls.toDate.value.format();

    this.busy = this.accountStatementService.generateAgentAccountStatement(
      this.accountId,
      this.startDate,
      this.endDate,
      this.paginationConfig.currentPage,
      this.paginationConfig.itemsPerPage)
      .subscribe(data => {
        this.isDownloadButtonEnable = false;
        this.transactionList = data;
        this.paginationConfig.totalItems = data.Total;
        var nullableValueExists = this.transactionList.List.find(x => x.BalanceAfter == null && x.BalanceBefore == null);
        if (nullableValueExists == undefined) {
          this.isDownloadButtonEnable = true;
        }
        console.log("number of items:" + this.paginationConfig.totalItems);
        console.log("number of items:" + this.maxNumberOfItems);
      }, error => {
        console.log(error);
      });
  }


  downloadAccountStatement(value: string): void {

    let isPdfDownlaod: boolean;
    if (value == "PDF") {
      isPdfDownlaod = true;
    } else {
      isPdfDownlaod = false;
    }

    if (!this.isDownloadButtonEnable) {
      this.notif.error("Account statement for this period is not yet ready, please check back later.");
      return;
    }

    this.trackGenerateAccountStatement(false);
    this.startDate = this.form.controls.fromDate.value.format();
    this.endDate = this.form.controls.toDate.value.format();

    this.busy = this.accountStatementService.downloadAgentAccountStatement(
      this.accountId,
      this.startDate,
      this.endDate,
      isPdfDownlaod
      ).subscribe(
        response => {
          var blob = new Blob([response]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = isPdfDownlaod ? `${response.fileName}.pdf`:`${response.fileName}.csv`;
          link.click();
        },
        error => {
          console.log(error);
        }
      );
  }

  clear(): void {
    this.transactionList = new TransactionInfoList;
    this.form.reset();
    this.startDate = null;
    this.endDate = null;
    this.paginationConfig = new PaginationConfig();
  }

  validateDates(): boolean {
    let isValid = false;
    let sDate = this.form.controls.fromDate.value.format();
    let eDate = this.form.controls.toDate.value.format();
    if ((sDate && eDate) && (eDate) < (sDate)) {
      isValid = true;
    }
    return isValid;
  }

  paginationPageChanged(pageNumber: number) {
    this.paginationConfig.currentPage = pageNumber;
    this.GetAccountStatement();
  }

  onSubmit() {
    this.paginationConfig.currentPage = 1;
    this.trackGenerateAccountStatement(true);
    this.GetAccountStatement();
    this.selectedDownloadValue = undefined;
  }

  trackGenerateAccountStatement(actStatement: boolean) {
    let startDateEvent = this.form.controls.fromDate.value.format();
    let endDateEvent = this.form.controls.toDate.value.format();
    this.mixPanelUtilService.generateAccountStatementEventLog(startDateEvent, endDateEvent,
      actStatement, !actStatement);
  }
}
