import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../../services/auth.service';
import { User } from '../../../models/user';
import { CurrencyService } from 'app/services/currency.service';
import { Currency } from 'app/models/business';
import { BusinessService } from 'app/services/business.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    public currentUser: User;
    public currentCurrency: Currency;
    public hasApiKeyPermission: boolean = false;
    public hasPaymentRequestReversalPermission: boolean = false;
    public hasBankWithdrawInititatePermission: boolean = false;
    public hasBusinessMoneyTransferPermission: boolean = false;
    public isUserPaymentApprover: boolean = false;
    public showDropdownMenu: boolean = false;
    public menuName: string;
    public isBusinessAgentGroup: boolean = false;
    constructor(private authenticationService: AuthenticationService,
        private currencyService: CurrencyService, private businessService: BusinessService) {
        this.authenticationService.currentUser$.subscribe(
            user => {
                this.currentUser = user;
                // this.hasApiKeyPermission = this.authenticationService.isUserInPermissionGroup("SpennBusinessApiKeyGenerator");
                // this.hasPaymentRequestReversalPermission = this.authenticationService.isUserInPermissionGroup("SpennBusinessRequest");
            }
        );


    }
    ngOnInit() {
        this.currentCurrency = this.currencyService.getCurrentCurrency();
        this.authenticationService.permissionStateChanged$
            .subscribe((res) => {
                console.log(res);
                this.hasApiKeyPermission = this.authenticationService.isUserInPermissionGroup("SpennBusinessApiKeyGenerator");
                this.hasPaymentRequestReversalPermission = this.authenticationService.isUserInPermissionGroup("SpennBusinessRequest");
                this.hasBankWithdrawInititatePermission = this.authenticationService
                    .isUserInPermissionGroup("SpennBusinessBankWithdrawInitiator");
                this.hasBusinessMoneyTransferPermission = this.authenticationService
                    .isUserInPermissionGroup("SpennBusinessKremitOutgoing");
                this.isUserPaymentApprover = this.authenticationService.isUserInPermissionGroup("SpennBusinessPaymentApprover");    
        });
        
        this.businessService.IsBusinessAgentGroup().subscribe((result) => {
            this.isBusinessAgentGroup = result;
        })
    }
    changeMenu(value: string) {
        if (this.menuName == value) {
            this.menuName = null;
            return;
        }
        this.menuName = value;
    }
}
