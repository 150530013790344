import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, throwError, BehaviorSubject } from "rxjs";

import { Account, AccountList, AgentGroupListDetailsView } from '../models/business';
import { Configuration } from "../app.constants";
import { TransactionList, GetTransactionCommand, GetTransactionListCommand, TransactionGroupByTransactionTypeList, TransactionGroupByDateList, SavingsTransactionList } from 'app/models/transaction';

@Injectable({ providedIn: 'root' })
export class BusinessService {
  public account: Account;
  public readonly account_storage: string = 'bb_account';
  private currentUserAccount = new BehaviorSubject<Account>(null);
  userAccount$ = this.currentUserAccount.asObservable();

  constructor(private http: HttpClient) {
    this.currentUserAccount.next(this.GetAccountInLocalStorage());
  }

  public UpdateAccountInLocalStorage(acc: Account) {
    this.account = acc;
    this.currentUserAccount.next(acc);
    localStorage.setItem(this.account_storage, JSON.stringify(this.account));
  }

  public GetAccountInLocalStorage() {
    if (this.account != null) return this.account;

    var acc = localStorage.getItem(this.account_storage);
    if (acc)
      this.account = JSON.parse(acc);
    else
      this.account = new Account();
    return this.account;
  }

  public RemoveAccontInLocalStorage() {
    this.account = null;
    this.currentUserAccount.next(null);
    localStorage.removeItem(this.account_storage);
  }

  public GetCurrentBalance(): Observable<Account> {
    return this.http.get(Configuration.GetAccountBalanceUri)
      .map(data => {
        var acc = new Account(data);
        this.UpdateAccountInLocalStorage(acc);
        return acc;
      })
      .catch(err => { return throwError(err) });
  }

  public GetSavingsBalance(): Observable<Account> {
    return this.http.get(Configuration.GetAccountBalanceForSavingsUri)
      .map(data => {
        var acc = new Account(data);
        return acc;
      })
      .catch(err => { return throwError(err) });
  }

  public GetAllAccountsBalance(): Observable<AccountList> {
    return this.http.get(Configuration.GetAllAccountsBalanceUri)
      .map(data => { return new AccountList(data); })
      .catch(err => { return throwError(err) });
  }

  public CreateSavingsAccount(): Observable<Account> {
    return this.http.post(Configuration.CreateSavingsAccountUrl, null)
      .map(data => {
        var acc = new Account(data);
        return acc;
      })
      .catch(err => { return throwError(err) });
  }

  public GetTransactions(businessId: number, page: number = 1, pageSize: number = 10, isDashboard = false): Observable<TransactionList> {
    var command = new GetTransactionCommand();
    command.AccountGuid = this.GetAccountInLocalStorage().AccountId;
    command.Page = page;
    command.PageSize = pageSize;

    let url = `${Configuration.ApiRoot}/api/external/${businessId}`;
    url = isDashboard ? `${url}/dashboard/transactions` : `${url}/transactions`;

    return this.http.post(url, command)
      .map(data => { return new TransactionList(data); })
      .catch(err => { return throwError(err) });
  }

  /* dashboard changes added here */
  public GetTransactionsGroupByDate(businessId: number, fromDate: Date, toDate: Date): Observable<TransactionGroupByDateList> {
    var command = new GetTransactionListCommand();
    command.AccountGuid = this.GetAccountInLocalStorage().AccountId;
    command.FromDate = fromDate;
    command.ToDate = toDate;


    return this.http.post(`${Configuration.ApiRoot}/api/external/${businessId}/transactionsbydate`, command)
      .map(data => { return new TransactionGroupByDateList(data); })
      .catch(err => { return throwError(err) });
  }

  public GetTransactionsGroupByTransactionType(businessId: number, fromDate: Date, toDate: Date): Observable<TransactionGroupByTransactionTypeList> {
    var command = new GetTransactionListCommand();
    command.AccountGuid = this.GetAccountInLocalStorage().AccountId;
    command.FromDate = fromDate;
    command.ToDate = toDate;


    return this.http.post(`${Configuration.ApiRoot}/api/external/${businessId}/transactionsbytype`, command)
      .map(data => { return new TransactionGroupByTransactionTypeList(data); })
      .catch(err => { return throwError(err) });
  }

  public GetOnlyCreditTransactions(
    businessId: number,
    page: number = 1,
    pageSize: number = 10,
    isDashboard = false
  ): Observable<TransactionList> {
    var command = new GetTransactionCommand();
    command.AccountGuid = this.GetAccountInLocalStorage().AccountId;
    command.Page = page;
    command.PageSize = pageSize;

    let url = `${Configuration.ApiRoot}/api/external/${businessId}`;
    url = isDashboard
      ? `${url}/dashboard/transactions/true`
      : `${url}/transactions`;

    return this.http
      .post(url, command)
      .map((data) => {
        return new TransactionList(data);
      })
      .catch((err) => {
        return throwError(err);
      });
  }

  public downloadQRCode(): Observable<any> {

    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };
    return this.http.get(Configuration.SBWebQrPdfDownload, httpOptions)
      .map((response: any) => {
        if (response.status == 200) {
          let fileName = `QR Code`;
          response.body.fileName = fileName;
          return response.body;
        }
      })
      .catch(err => { return throwError(err) });
  }


  public GetAllSavingsAccountTransactions(page: number = 1, pageSize: number = 10): Observable<SavingsTransactionList> {
    var command = new GetTransactionCommand();
    command.Page = page;
    command.PageSize = pageSize;

    let url = `${Configuration.ApiRoot}/api/external/savings/transactions`;
    return this.http.post(url, command)
      .map(data => { return new SavingsTransactionList(data); })
      .catch(err => { return throwError(err) });
  }

  public IsBusinessAgentGroup(): Observable<boolean> {
    return this.http.get(Configuration.IsBusinessAgentGroup)
      .map(data => { return Boolean(data); })
      .catch(err => { return throwError(err) });
  }

  public GetAgentGroupDetails(page: number, pageSize: number): Observable<AgentGroupListDetailsView> {
    return this.http.get(Configuration.GetAgentGroupDetails + "?page="+page+"&pagesize="+pageSize)
      .map(data => { return new AgentGroupListDetailsView(data); })
      .catch(err => { return throwError(err) });
  }

}
