<app-bb-toolbar [title]="pageTitle"></app-bb-toolbar>
<app-spinner [spin]="busy"></app-spinner>
<div class="container-fluid" ngClass.lt-md="mobile">
<div class="table-responsive table-striped mt-2">
    <table class="table table-borderless">
        <thead style="border-bottom: 1px solid #217E6E">
            <tr>
                <th>AgentName</th>
                <th>AgentNumber</th>
                <th>PhoneNumber</th>
                <th>Balance</th>
                <th>Account Statement</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let agentDetail of agentDetails?.Data | paginate: paginationConfig;">
                <td>{{agentDetail.AgentName}}</td>
                <td>{{agentDetail.AgentNumber}}</td>
                <td>{{agentDetail.PhoneNumber}}</td>
                <td class="py-0 align-middle" style="min-width: 120px;">
                    <span *ngIf="agentDetail.HasAccount">{{agentDetail.Balance | formatMoney}} {{true |
                        formatCurrency}}</span>
                    <span *ngIf="!agentDetail.HasAccount">N/A</span>    
                </td>
                <td><button *ngIf="agentDetail.AccountId != null" type="button" class="btn-default text-spenn p-0 mx-2 my-0" title="Download"
                    (click)="accountStatement(agentDetail.AccountId)">Download</button>
                    <span *ngIf="agentDetail.AccountId == null">N/A</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-pagination [paginationConfig]="paginationConfig" (pageChangedCallback)="paginationPageChanged($event)">
</app-pagination>
</div>
