export class TransactionInfo {
    public Date: string;
    public Time: string;
    public CounterParty: string;
    public CounterPartyPhoneNumber: string;
    public Amount: number;
    public BalanceBefore: number;
    public BalanceAfter: number;
    public Description: string;
    public IsCredit: boolean;
    public Status: string;

    public constructor(data: any = {}) {
        this.Date = data.date || '';
        this.Time = data.time || '';
        this.CounterParty = data.counterParty || '';
        this.CounterPartyPhoneNumber = data.counterPartyPhoneNumber || '';
        this.Amount = data.amount || 0;
        this.BalanceBefore = data.balanceBefore || null;
        this.BalanceAfter = data.balanceAfter || null;
        this.Description = data.description || '';
        this.IsCredit = data.isCredit || false;
        this.Status = data.status || '';
    }
}

export class TransactionInfoList {
    public List: Array<TransactionInfo>;
    public Total: number;

    public constructor(data: any = {}) {
        this.List = new Array<TransactionInfo>();
        this.Total = data.total || 0;
        if (data.data) {
            data.data.forEach(item => {
                this.List.push(new TransactionInfo(item));
            })
        }
    }
}

export interface GetAccountStatementDownloadCommand {
    BusinessId: number;
    AccountGuid: string;
    FromDate: Date;
    ToDate: Date;
    IsSavingsAccount: boolean;
}

export class GetAccountStatementCommand {
    public BusinessId: number;
    public AccountGuid: string;
    public FromDate: Date;
    public ToDate: Date;
    public IsSavingsAccount: boolean;
    public Page: number;
    public PageSize: number;
    public Sort: AccountStatementSortDescriptor[];

    public constructor() {
        this.Sort = new Array<AccountStatementSortDescriptor>();
        this.Page = 1;
        this.PageSize = 10;
    }
}

export class GetAgentAccountStatementCommand {
    public AccountId: number;
    public FromDate: Date;
    public ToDate: Date;
    public Page: number;
    public PageSize: number;
    public Sort: AccountStatementSortDescriptor[];

    public constructor() {
        this.Sort = new Array<AccountStatementSortDescriptor>();
        this.Page = 1;
        this.PageSize = 10;
    }
}

export class AccountStatementSortDescriptor {
    public Field: string;
    public Dir: string;

    public constructor(field: string = '', dir: string = '') {
        this.Field = field;
        this.Dir = dir;
    }
}