import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageTemplateComponent } from './components/template/page-template.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard, AuthGuardWithRequirePasswordChange } from './helpers/auth.guard'
import { LogoutComponent } from './components/logout/logout.component';
import { UserLockComponent } from './components/user-lock/user-lock.component';
import { EmptyComponent } from './components/empty/empty.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { FailedPaymentListItemViewComponent } from './components/payments/failed-payment-list-item-view/failed-payment-list-item-view.component';
import { StatementComponent } from './components/statement/statement.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import { NfsFailedPaymentListItemComponent } from './components/payments/nfs-bulk/nfs-failed-payment-list-item/nfs-failed-payment-list-item.component';
import { BankMomoComponent } from './components/topup/modals/bank-momo/bankmomo.component';
import { AgentGroupsComponent } from './components/agent-groups/agent-groups.component';
import { AgentAccountStatementComponent } from './components/agent-groups/agent-account-statement/agent-account-statement.component';

export const routes: Routes = [
  {
  canActivate: [AuthGuard],
  path: '',
  component: EmptyComponent,
  pathMatch: 'full'
}, 
{
  path: '',
  component: PageTemplateComponent,
  data: {
    title: 'Spenn Business'
  },
  children: [

    {
      path: 'payments',
      loadChildren: () => import('../app/components/payments/payments.module').then(m => m.PaymentsModule)
    },
    {
      canActivate: [AuthGuard],
      path: 'profile',
      component: ProfileComponent,
      data: {
        title: 'Profile'
      },
    },
    {
      path: 'transactions',
      loadChildren: () => import('../app/components/transactions/transactios.module').then(m => m.TransactiosModule)
    },

    {
      path: 'requests',
      loadChildren: () => import('../app/components/requests/request.module').then(m => m.RequestModule)
    },

    {
      path: 'transfer',
      loadChildren: () => import('../app/components/transfer/transfer.module').then(m => m.TransferModule)
    },

    {
      path: 'api-keys',
      loadChildren: () => import('../app/components/api-keys/api-keys.module').then(m => m.ApiKeysModule)
    },
    {
      path: 'logout',
      component: LogoutComponent,
      data: {
        title: 'Logout'
      },
    },
    
    {
      path: 'dashboard',
      loadChildren: () => import('../app/components/dashboard/dashboard.module').then(m => m.DashboardModule)
    },

    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'failedItems/:paymentId',
      component: FailedPaymentListItemViewComponent,
      data: {
        title: 'Failed Items'
      }
    },
    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'nfsfailedItems/:paymentId',
      component: NfsFailedPaymentListItemComponent,
      data: {
        title: 'NFS Failed Items'
      }
    },
    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'withdraw',
      component: WithdrawComponent,
      data: {
        title: 'Withdraw'
      }
    },
    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'topup',
      loadChildren: () => import('./components/topup/topup.module')
        .then(x => x.TopupModule),
      data: {
        title: 'Top up'
      }
    },
    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'Bank&MOMO',
      component: BankMomoComponent,
      data: {
        title: 'Bank & MoMo'
      }
    },
    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'AgentDetails',
      component: AgentGroupsComponent,
      data: {
        title: 'Agent Details'
      }
    },
    {
      canActivate: [AuthGuardWithRequirePasswordChange],
      path: 'AgentAccountStatement/:id',
      component: AgentAccountStatementComponent,
      data: {
        title: 'Agent Account Statement'
      }
    },
  ]
},
{
  path: 'Landing-page',
  component: LandingPageComponent,
  data: {
    title: 'Spenn Business | Landing'
  }
},
{
  path: 'AccessDenied',
  component: UserLockComponent,
  data: {
    title: 'user-lock'
  },
},
{
  canActivate: [AuthGuardWithRequirePasswordChange],
  path: 'payments/:id/statement',
  component: StatementComponent,
  data: {
    title: 'Spenn Business | Payment Statement'
  },
},

{
  path: '**',
  component: PageNotFoundComponent
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
