<app-spinner [spin]="busy"></app-spinner>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <h5 class="text-default modal-title">Download account statement</h5>
    <a class="close_btn" mat-dialog-close>
      <mat-icon class="md-30">clear</mat-icon>
    </a>
  </mat-dialog-actions>
<mat-dialog-content>
    <div fxLayout>
        <div fxLayout="column">
            <mat-card>
                <mat-card-content>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutGap.sm="5px" [formGroup]="form">
                        <div fxFlex>
                            <mat-label color="primary" class="mat-small text-spenn">Select Start
                                Date(mm/dd/yyyy):
                            </mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="startpicker" [max]="maxDate"
                                    formControlName="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                                <mat-datepicker #startpicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex>
                            <mat-label color="primary" class="mat-small text-spenn">Select End Date(mm/dd/yyyy):
                            </mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="endpicker" [max]="maxDate" formControlName="toDate">
                                <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                                <mat-datepicker #endpicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex>
                            <div fxLayout fxLayoutGap="10px" fxFlexFill fxLayoutAlign="end center">
                                <div fxLayoutAlign="end center">
                                    <button mat-raised-button class=" spenn-btn spenn-btn-white mat-button-sm"
                                        (click)="clear()">Clear</button>
                                </div>
                                <div fxLayoutAlign="end center">
                                    <button mat-raised-button color="primary" class="spenn-btn mat-button-sm"
                                        [disabled]="!form.valid" (click)="onSubmit()">Apply</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </mat-card-content>

            </mat-card>
        </div>

    </div>

    <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" style="margin-top: 30px;">
        <div fxFlex>
            <mat-card>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="column" fxLayoutGap="5px" fxFill>
                        <div fxLayout="row">
                            <div *ngIf="startDate && endDate" fxFlex="80" fxLayout="row" fxLayoutAlign="start center">
                                <mat-label color="primary" class="mat-body-2"> Transactions for the period {{startDate|
                                    date:
                                    'dd
                                    MMMM yyyy'}} - {{endDate| date: 'dd MMMM yyyy'}}</mat-label>
                            </div>
                            <div *ngIf="transactionList?.List?.length>0" fxFlex fxLayout="row"
                                fxLayoutAlign="end center">

                                <mat-select [(value)]="selectedDownloadValue" placeholder="Download"
                                    (selectionChange)="downloadAccountStatement($event.value)"
                                    class="btn-square spenn-btn box-shadow-none">
                                    <mat-select-trigger>{{selectedDownloadValue}}</mat-select-trigger>
                                    <mat-option *ngIf="paginationConfig.totalItems<=maxNumberOfItems"
                                        value="PDF">PDF</mat-option>
                                    <mat-option value="CSV">CSV</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div fxFlex>
                            <table class="table">
                                <thead>
                                    <th>Date and Time</th>
                                    <th>Counterparty</th>
                                    <th>Phone Number</th>
                                    <th>Description</th>
                                    <th> Amount ({{true | formatCurrency}})</th>
                                    <th>Balance</th>
                                </thead>
                                <tbody>
                                    <tr *ngIf="transactionList?.List?.length==0">
                                        <td colspan="5">THERE ARE NO RECORDS FETCHED</td>
                                    </tr>
                                    <tr
                                        *ngFor="let item of transactionList?.List | paginate: paginationConfig; let i = index">
                                        <td>{{item.Date| date: 'yyyy/MM/dd'}} {{item.Time}}</td>
                                        <td>{{item.CounterParty}}</td>
                                        <td>{{item.CounterPartyPhoneNumber}}</td>
                                        <td>{{item.Description}}</td>
                                        <td>{{item.IsCredit?"+":"-"}} {{item.Amount | formatMoney}}</td>
                                        <td>{{item.BalanceAfter == null ? '' : (item.BalanceAfter | formatMoney)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-card>
            <app-pagination [paginationConfig]="paginationConfig" (pageChangedCallback)="paginationPageChanged($event)">
            </app-pagination>
        </div>
    </div>
</mat-dialog-content>