<div fxLayout="column" fxFill class="sidebar hidden-sm-down">
  <ul fxFlex class="nav in" id="side-menu">
    <mat-nav-list>
      <li class="user-pro">
        <a (click)="changeMenu('')" mat-list-item [routerLink]="['/profile']" [routerLinkActive]="['active']"
          title="{{this.currentUser?.SelectedBusiness?.Name}}">
          <span>{{this.currentUser?.SelectedBusiness?.Name}}
            <small>{{this.currentUser?.Email}}</small>
          </span>
        </a>
      </li>
      <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false">
        <a (click)="changeMenu('')" [routerLink]="['/dashboard']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/transaction-history.png" alt="dashboard">
          <span class="hide-menu">Dashboard</span>
        </a>
      </li>
      <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false">
        <a (click)="changeMenu('')" [routerLink]="['/payments']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/payments.png" alt="payments-icon">
          <span class="hide-menu">Payments</span>
        </a>
      </li>
      <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false && hasApiKeyPermission">
        <a (click)="changeMenu('')" [routerLink]="['/api-keys']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/api-keys.png" alt="api-keys">
          <span class="hide-menu">API Keys</span>
        </a>
      </li>
      <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false">
        <a (click)="changeMenu('')" [routerLink]="['/transactions']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/transaction-history.png" alt="transaction-history">
          <span class="hide-menu">Transaction History</span>
        </a>
      </li>
      <li *ngIf="isBusinessAgentGroup">
        <a (click)="changeMenu('')" [routerLink]="['/AgentDetails']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/Group.png" alt="Agent Group">
          <span class="hide-menu">Agents</span>
        </a>
      </li>
      <!-- <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false && hasPaymentRequestReversalPermission">
        <a [routerLink]="['/requests']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/payment-requests.svg" alt="requests">
          <span class="hide-menu">Requests</span>
        </a>
      </li> -->
      <!-- <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false && hasBankWithdrawInititatePermission"> 
        <a [routerLink]="['/withdraw']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/cashout.png" alt="withdraw">
          <span class="hide-menu">Withdraw</span>
        </a>
      </li> -->
      <li *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false">
        <a (click)="changeMenu('Requests')" [routerLinkActive]="['active']" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div>
            <img class="sidebar-icon" src="assets/images/payment-requests.svg" alt="requests">
            <span class="hide-menu">Requests</span>
          </div>
          <span *ngIf="menuName=='Requests'" class="material-icons nav-icon">
            keyboard_arrow_down
          </span>
          <span *ngIf="menuName!='Requests'" class="material-icons nav-icon">
            keyboard_arrow_right
          </span>
        </a>
        <ul class="sub-menu" *ngIf="menuName=='Requests'">
          <li>
            <a [routerLink]="['/requests','create']" [routerLinkActive]="['active']"> Payment Request </a>
          </li>
          <li>
            <a [routerLink]="['/requests','reverse']" [routerLinkActive]="['active']"> Reverse Request </a>
          </li>
        </ul>
      </li>
      <li
        *ngIf="currentUser && currentUser.IsRequiredPasswordChange==false && (currentCurrency.CurrencyId==9 || hasBankWithdrawInititatePermission)">
        <a (click)="changeMenu('Transfer')" [routerLinkActive]="['active']" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div>
            <img class="sidebar-icon" src="assets/images/transfer.png" alt="transfer">
            <span class="hide-menu">Transfer</span>
          </div>
          <span *ngIf="menuName=='Transfer'" class="material-icons nav-icon">
            keyboard_arrow_down
          </span>
          <span *ngIf="menuName!='Transfer'" class="material-icons nav-icon">
            keyboard_arrow_right
          </span>
        </a>
        <ul class="sub-menu" *ngIf="menuName=='Transfer'">
          <li *ngIf="currentCurrency.CurrencyId==13 && hasBankWithdrawInititatePermission">
            <a [routerLink]="['/transfer','NfsBank']" [routerLinkActive]="['active']"> Transfer to Bank </a>
          </li>
          <li *ngIf="currentCurrency.CurrencyId==13 && hasBankWithdrawInititatePermission">
            <a [routerLink]="['/transfer','NfsMomo']" [routerLinkActive]="['active']"> Mobile Money Operator </a>
          </li>
          <li *ngIf="currentCurrency.CurrencyId==9">
            <a [routerLink]="['/transfer','InmBank']" [routerLinkActive]="['active']">I&M Bank Transfer </a>
          </li>
          <li *ngIf="currentCurrency.CurrencyId==10">
            <a [routerLink]="['/transfer','BillPayment']" [routerLinkActive]="['active']"> Mobile Money Operator </a>
          </li>
          <li *ngIf="hasBankWithdrawInititatePermission">
            <a [routerLink]="['/transfer','OtherBank']" [routerLinkActive]="['active']"> Other Bank Transfer </a>
          </li>
          <li *ngIf="isUserPaymentApprover && currentCurrency.CurrencyId==9">
            <a [routerLink]="['/transfer','moneyTransfer','Bank']" [routerLinkActive]="['active']"> Transfer to Bank
            </a>
          </li>
          <li *ngIf="isUserPaymentApprover && currentCurrency.CurrencyId==9">
            <a [routerLink]="['/transfer','moneyTransfer','MobileMoney']" [routerLinkActive]="['active']"> Transfer to
              Mobile Money </a>
          </li>
        </ul>
      </li>
      <li class="mt-2">
        <a [routerLink]="['/logout']" [routerLinkActive]="['active']">
          <img class="sidebar-icon" src="assets/images/logout.png" alt="logout">
          <span class="hide-menu">Sign out</span>
        </a>
      </li>
    </mat-nav-list>

  </ul>

  <div fxFlex fxLayoutAlign="center end" class="logo-footer">
    <img src="assets/images/latest_logo/SPENN_Logotype_White_Business.svg" alt="spenn business logo">
  </div>
</div>