import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from 'app/shared/material';
import { SharedModule } from 'app/components/shared/shared.module';
import { AgentGroupsComponent } from './agent-groups.component';
import { AgentAccountStatementComponent } from './agent-account-statement/agent-account-statement.component';

@NgModule({
  declarations: [AgentGroupsComponent,AgentAccountStatementComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    FlexLayoutModule,
    NgxPaginationModule,
    ReactiveFormsModule
  ],
  exports:[
    AgentGroupsComponent,AgentAccountStatementComponent]
})
export class AgentGroupModule { }
