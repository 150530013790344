import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgentGroupListDetailsView } from 'app/models/business';
import { PaginationConfig } from 'app/models/paginationConfig';
import { BusinessService } from 'app/services/business.service';
import { Subscription } from 'rxjs';
import { AgentAccountStatementComponent } from './agent-account-statement/agent-account-statement.component';

@Component({
  selector: 'app-agent-groups',
  templateUrl: './agent-groups.component.html',
  styleUrls: ['./agent-groups.component.scss']
})
export class AgentGroupsComponent implements OnInit {
  public pageTitle = "Agent Details";
  public busy : Subscription;
  public agentDetails : AgentGroupListDetailsView;
  public paginationConfig = new PaginationConfig('Agents', 'agent-group', 10);
  constructor(private businessService: BusinessService, private route: Router, public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getAgentGroupDetails();
  }

  getAgentGroupDetails(): any {
    this.busy = this.businessService.GetAgentGroupDetails(this.paginationConfig.currentPage, this.paginationConfig.itemsPerPage).
    subscribe((result) => {
      this.agentDetails = result;
      this.paginationConfig.totalItems = result.Total;
    })
  }

  accountStatement(id: number){
    this.dialog.open(AgentAccountStatementComponent, {
      data: {accountId : id }});
  }

  paginationPageChanged(pageNumber: number) {
    this.paginationConfig.currentPage = pageNumber;
    this.getAgentGroupDetails();
  }

}
